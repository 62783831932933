import Cookies from 'bloko/common/Cookies';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import Debug from 'HHC/Debug';

const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term'];
const FLOODLIGHTS_STORAGE = 'floodlights';

const FLOODLIGHT_MAP: Partial<Record<number, string>> = {
    1: '8649452',
    3: '11556056',
};

const getUtmData = (params: Partial<Record<string, string>>) => {
    const urlParams = new URLSearchParams(window.location.search);
    return utmParams.reduce((prev, param, index) => {
        prev += `u${index + 1}=${urlParams.get(param) || params[param] || ''};`;
        return prev;
    }, '');
};

const getFloodlights = () => {
    try {
        return JSON.parse(LocalStorageWrapper.getItem(FLOODLIGHTS_STORAGE) || '{}') as Record<
            string,
            SendFloodlightConversionParams
        >;
    } catch (error) {
        return {};
    }
};

const floodlightToStorage = (params: SendFloodlightConversionParams) => {
    const floodlights = getFloodlights();
    const { type, cat } = params;
    const hash = [type, cat].join('*');
    floodlights[hash] = { ...params };
    LocalStorageWrapper.setItem(FLOODLIGHTS_STORAGE, JSON.stringify(floodlights));
    Debug.log('info', 'Floodlights storaged:', type, cat);
};

export const floodlightProcessPostponed = (): void => {
    const floodlights = getFloodlights();
    for (const hash in floodlights) {
        SendFloodlightConversion(floodlights[hash]);
    }
    LocalStorageWrapper.removeItem(FLOODLIGHTS_STORAGE);
};

interface SendFloodlightConversionParams {
    type: string;
    cat: string;
}

export default function SendFloodlightConversion(params: SendFloodlightConversionParams, toStorage = false): void {
    if (window.globalVars.siteId === '76') {
        return;
    }
    if (toStorage) {
        floodlightToStorage(params);
    } else {
        const floodlightId = FLOODLIGHT_MAP[Number(window.globalVars.country)];
        if (floodlightId) {
            const a = Math.random() * 10000000000000;
            const clientID = Cookies.get('_gid')?.split('.').slice(-2).join('.') || '';
            const { type, cat, ...utmData } = params;
            let tagURL = `https://${floodlightId}.fls.doubleclick.net/activityi;src=${floodlightId};type=${type};cat=${cat};`;

            tagURL += getUtmData(utmData);
            tagURL += `u5=${clientID};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=${a}?`;
            const iframe = document.createElement('iframe');
            iframe.src = tagURL;
            iframe.className = 'g-hidden';

            if (document.readyState === 'complete') {
                document.body.appendChild(iframe);
            } else {
                const interval = setInterval(() => {
                    if (document.readyState === 'complete') {
                        document.body.appendChild(iframe);

                        clearInterval(interval);
                    }
                }, 100);
            }
        }
    }
}
